import styled, { css } from 'styled-components';
import { FlexCenterAligned } from '../../../styled/common';

const inputContainerStyles = css`
  width: 135px;
  margin: 0;
  height: 44px;
  padding: 13px 10px;
`;

const inputStyles = css`
  margin: 0;
  padding-bottom: 0;
  padding-right: 10px;
  border: none;
`;

const clearIconStyles = css`
  top: 14px;
  right: 10px;
  path {
    fill: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 35px;
  background-color: ${({ theme }) => theme.colors.border.primary};
`;

export const RangeFilterContainer = styled(FlexCenterAligned)`
  width: 270px;
  height: 44px;
  box-sizing: border-box;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  position: relative;

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.systemColors.blue};
    ${Separator} {
      background-color: ${({ theme }) => theme.colors.systemColors.blue};
    }
  }

  > div:nth-child(odd) {
    ${inputContainerStyles}

    > div {
      ${clearIconStyles}
    }

    input {
      ${inputStyles}
    }
  }
`;
