import styled, { css } from 'styled-components';
import { Field } from 'react-final-form';
import {
  customScrollbar,
  ellipsisText,
  FlexibleDiv
} from '../../../styled/common';
import { tagStyles } from '../Typography/styles';
import { InputSize } from './types';
import {
  ICON_PADDING,
  RESET_ICON_SIZE,
  SEARCH_ICON_SIZE,
  VISIBLE_VALUE_ICON_SIZE
} from './constants';

const getBasePadding = size => (size === InputSize.Large ? 20 : 15);
const getLeftPadding = (isShowSearch, basePadding) =>
  isShowSearch ? SEARCH_ICON_SIZE + ICON_PADDING + basePadding : basePadding;

const getInputPadding = ({
  isLabelAnimated,
  isShowSearch,
  isValueHidden,
  isShowReset,
  size
}) => {
  const top = isLabelAnimated ? 20 : 10;
  const basePadding = getBasePadding(size);
  const left = getLeftPadding(isShowSearch, basePadding);
  const valueHiddenIcon = isValueHidden
    ? VISIBLE_VALUE_ICON_SIZE + ICON_PADDING
    : 0;
  const showReset = isShowReset ? RESET_ICON_SIZE + ICON_PADDING : 0;
  const right = basePadding + valueHiddenIcon + showReset;

  return { top, basePadding, left, right };
};

export const maskedInputField = css`
  height: 100%;
  width: 100%;
  font-size: 15px;
  border: 0;
  outline: none;
  padding: 28px 0 9px;
  font-weight: 400;
  color: ${({ theme: { colors } }) => colors.text.primary};
  background-color: transparent;
  z-index: 2;
`;

export const TextFieldContainer = styled.div`
  width: 100%;
  display: flex;
  height: 55px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTextField = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 55px;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .masked-input-field {
    ${maskedInputField}
  }
`;

export const StyledTextLabel = styled.div`
  position: absolute;
  left: 0;
  top: ${({ isNumber }) => (isNumber ? '-9px' : 'unset')};
  transition: transform 0.1s ease-in-out;
  color: ${({ theme: { colors } }) => colors.text.secondary};
  z-index: 3;
  ${({ isActive }) => tagStyles[isActive ? 'body_1' : 'body_2']}

  ${({ isActive, $isBold }) =>
    isActive && `transform: translateY(-${$isBold ? 15 : 10}px);`}
`;

export const StyledField = styled(Field)`
  height: 100%;
  width: 100%;
  border: 0;
  outline: none;
  padding: 28px 0 9px;
  color: ${({ theme: { colors } }) => colors.text.primary};
  background-color: transparent;
  z-index: 2;
  ${({ $isBold }) => tagStyles[$isBold ? 'numbers_1' : 'body_1']}
`;

export const StyledAreaField = styled(Field)`
  resize: none;
  width: 100%;
  height: 100%;
  min-height: 88px;
  max-height: 268px;
  ${tagStyles.body_1}
  padding-right: 10px;
  border: 0;
  outline: none;
  color: ${({ theme: { colors } }) => colors.text.primary};
  font-family: inherit;

  &::placeholder {
    ${tagStyles.body_1}
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme: { colors } }) => colors.text.secondary};
  }
`;

export const TextAreaContainer = styled.div`
  ${customScrollbar(0)}
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${({ theme: { colors } }) => colors.border.primary};
  border-radius: 7px;
  padding: 15px 10px 15px 15px;
`;

export const NumberFieldContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'calc(50% - 7px)')};
  margin-right: ${({ isFullWidth }) => (isFullWidth ? '0' : '15px')};

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledDeleteIcon = styled.div`
  height: 15px;
  width: 15px;
  position: absolute;
  right: 0;
  top: -2px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const numberInputStyles = css`
  display: block;
  width: 100%;
  padding: 0 20px 9px 0;
  height: 26px;
  ${tagStyles.body_1}
  border: 0;
  outline: none;
  border-radius: 0;
  border-bottom: 0.5px solid ${({ theme: { colors } }) => colors.border.primary};
  margin-bottom: 5px;

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.text.secondary};
    opacity: 1;
  }
`;

export const StyledNumberField = styled(Field)`
  ${numberInputStyles};
`;

export const StyledNumberInput = styled.input`
  ${numberInputStyles};
`;

export const StyledSearchContainer = styled.div`
  flex: 1 0;
  display: flex;
  align-items: center;
  border-radius: 14px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};
  border: 1px solid ${({ theme: { colors } }) => colors.border.primary};
  background-color: ${({ theme: { colors } }) => colors.background.tertiary};
  position: relative;

  & > label {
    top: ${({ isActive }) => (isActive ? '10px' : '50%')};
    font-size: ${({ isActive }) => (isActive ? '12px' : '16px')};
    left: ${({ size, isShowSearch }) =>
      getLeftPadding(isShowSearch, getBasePadding(size))}px;
  }
`;

export const StyledSearchInput = styled.input`
  flex: 1 1;
  border: 0;
  height: ${({ size }) => size};
  outline: none;
  ${({ $inputTagStyle }) => $inputTagStyle};
  appearance: none;
  border-radius: 14px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};

  // The following empty animation is used to trigger the 'animationstart' event
  // for the \`:-webkit-autofill\` pseudo-class. Although it does not visually change anything,
  // it ensures that the browser fires the necessary event when autofill is applied.
  @keyframes autofill {
    to {
    }
  }

  &:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: both;

    & + label {
      font-size: 12px;
      top: 10px;
    }
  }

  ${({ isLabelAnimated, isShowSearch, isValueHidden, isShowReset, size }) => {
    const { top, right, left } = getInputPadding({
      isLabelAnimated,
      isShowSearch,
      isValueHidden,
      isShowReset,
      size
    });
    return {
      padding: `${top}px ${right}px 10px ${left}px`,
      width: `calc(100% - ${right + left}px)`
    };
  }};
  background-color: transparent;
  color: ${({ theme: { colors } }) => colors.text.primary};

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    appearance: none;
  }

  &::placeholder {
    padding-left: 5px;
    ${tagStyles.body_1}
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme: { colors } }) => colors.text.secondary};
    ${({ isLabelAnimated }) => isLabelAnimated && 'opacity: 0;'}
  }

  ${ellipsisText}
`;

export const IconContainer = styled(FlexibleDiv)`
  position: absolute;
  ${({ size, position = 'left' }) => ({
    [position]: `${getBasePadding(size)}px`
  })};
`;

const pinIcon = '/static/images/new_search/icons/icon-pin.svg';
const searchIcon = '/static/images/search/icons/icon-search.svg';

export const SearchIconContainer = styled(IconContainer)`
  background-size: ${SEARCH_ICON_SIZE}px;
  background-image: url('${({ isPin }) => (isPin ? pinIcon : searchIcon)}');
  width: ${SEARCH_ICON_SIZE}px;
  height: ${SEARCH_ICON_SIZE}px;
`;

export const StyledSearchResetButton = styled.div`
  width: ${RESET_ICON_SIZE}px;
  height: ${RESET_ICON_SIZE}px;
  margin-left: ${ICON_PADDING}px;
  box-sizing: border-box;
  background-size: ${RESET_ICON_SIZE}px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('/static/images/new_search/icons/icon-delete.svg');
`;

export const StyledSearchLabel = styled.label`
  position: absolute;
  transform: translateY(-50%);
  color: ${({ theme: { colors } }) => colors.text.secondary};
  transition: all 0.2s ease-in-out;
  pointer-events: none;
`;
