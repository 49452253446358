import React from 'react';
import {
  HeaderRightBlock,
  Headline,
  SectionHeaderContainer,
  StyledLink
} from './styled';
import { NewSectionHeaderProps } from './types';
import { CustomLink } from '../CustomLink';

export const NewSectionHeader = ({
  title,
  cypress,
  url = null,
  urlTitle = '',
  rightBlock = null,
  headlineType = 'h4',
  asLink = '',
  showBothRightBlocks = false
}: NewSectionHeaderProps) => {
  const SectionLink = url && (
    <CustomLink
      as={asLink}
      href={url}
      target="_self"
      cypress="show-all-link"
      stat={`${cypress}-all-link`}
    >
      <StyledLink tag="body_1">{urlTitle}</StyledLink>
    </CustomLink>
  );

  return (
    <SectionHeaderContainer>
      {typeof title === 'string' ? (
        <Headline tag={headlineType} data-cy={cypress}>
          {title}
        </Headline>
      ) : (
        <div data-cy={cypress}>{title}</div>
      )}
      <HeaderRightBlock>
        {showBothRightBlocks ? (
          <>
            {SectionLink}
            {rightBlock}
          </>
        ) : (
          rightBlock || SectionLink
        )}
      </HeaderRightBlock>
    </SectionHeaderContainer>
  );
};
