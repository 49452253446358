import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import { createBookmarkQuery, deleteBookmarkQuery } from '../../queries';
import Bugsnag from '../../utils/bugsnag';
import { BookmarkContainer, BookmarkLink } from './styled';
import {
  addBookmarkToCache,
  removeBookmarkFromCache
} from '../../utils/helpers/updateBookmarkCache';

class Bookmark extends Component {
  constructor(...props) {
    super(...props);
    this.state = { isActive: this.props.isActive };
  }

  static propTypes = {
    itemId: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    isCurrentItemPage: PropTypes.bool,
    removeItemFunc: PropTypes.func,
    addBookmark: PropTypes.func,
    deleteBookmark: PropTypes.func,
    client: PropTypes.object,
    additionalClasses: PropTypes.string,
    isGalleryHeader: PropTypes.bool,
    setFavorited: PropTypes.func,
    stat: PropTypes.string,
    isWebView: PropTypes.bool
  };

  addBookmark = itemId => {
    const { mutate } = this.props.client;

    mutate({ variables: { itemId }, mutation: createBookmarkQuery })
      .then(() => addBookmarkToCache(itemId, this.props.client))
      .catch(error => {
        Bugsnag.notify(error);
      });
  };

  deleteBookmark = itemId => {
    const { mutate } = this.props.client;

    mutate({ variables: { itemId }, mutation: deleteBookmarkQuery })
      .then(() => removeBookmarkFromCache(itemId, this.props.client))
      .catch(error => {
        Bugsnag.notify(error);
      });
  };

  toggleBookmark = e => {
    const { isActive } = this.state;
    const { itemId } = this.props;
    const action = !isActive ? this.addBookmark : this.deleteBookmark;

    e.preventDefault();
    if (this.props.setFavorited) {
      this.props.setFavorited(!isActive);
    }

    this.setState(previousState => {
      action(itemId);
      return {
        isActive: !previousState.isActive
      };
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.isActive !== this.props.isActive) {
      this.setState(() => {
        return {
          isActive: this.props.isActive
        };
      });
    }
  }

  render() {
    const {
      itemId,
      isCurrentItemPage,
      additionalClasses,
      isGalleryHeader,
      stat,
      isWebView
    } = this.props;
    const { isActive } = this.state;
    const itemIdValue = itemId ? ` ${itemId}` : '';
    const ariaLabel = isActive
      ? `Remove ad${itemIdValue} from bookmark`
      : `Add ad${itemIdValue} to bookmark`;

    return (
      <BookmarkContainer
        className={additionalClasses}
        isActive={isActive}
        isWebView={isWebView}
        isCurrentItemPage={isCurrentItemPage}
        isGalleryHeader={isGalleryHeader}
      >
        <BookmarkLink
          data-disable="true"
          href={`/bookmarks?card_view=true&amp;item_id=${itemId}`}
          onClick={this.toggleBookmark}
          data-cy="bookmark-icon"
          data-remote="true"
          data-method="post"
          data-stat={stat}
          data-action={isActive ? 'remove_bookmark' : 'add_bookmark'}
          aria-label={ariaLabel}
        />
      </BookmarkContainer>
    );
  }
}

Bookmark.defaultProps = {
  isActive: false,
  isWebView: false,
  isCurrentItemPage: false,
  isGalleryHeader: false
};

export default withApollo(Bookmark);
