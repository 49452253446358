import React from 'react';
import { NewTabProps } from './types';
import { TabContainer, TabText } from './styled';

export const Tab = ({
  cypress,
  text,
  isSelected,
  onClick,
  type = 'button',
  id,
  stat
}: NewTabProps) => {
  return (
    <TabContainer
      id={id}
      type={type}
      data-cy={cypress}
      data-stat={stat}
      onClick={onClick}
      $isSelected={isSelected}
    >
      <TabText $isSelected={isSelected} tag="body_1">
        {text}
      </TabText>
    </TabContainer>
  );
};
