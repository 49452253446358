import styled, { css } from 'styled-components';
import { FlexCenterAligned, arrow } from '../../../styled/common';
import { Typography } from '../Typography';

export const Header = styled(FlexCenterAligned)<{ height: number }>`
  position: fixed;
  padding: 0 15px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.primary};
  height: ${({ height }) => height}px;
  z-index: 99;
  -webkit-backface-visibility: hidden;
`;

export const HeaderLeftAction = styled.div`
  top: 0;
  left: 18px;
  position: absolute;
  height: 100%;
  width: 60px;
`;

const buttonStyles = css`
  height: 100%;
  width: 100%;
  vertical-align: middle;
  background: transparent;
  padding: 0;
  text-align: left;
`;

export const HeaderBackButton = styled.button`
  vertical-align: middle;
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: left 15px center;
  background-size: 4vw;
  ${buttonStyles};

  ${({ theme }) =>
    arrow({
      size: '12px',
      fill: theme.colors.text.secondary,
      bw: '2px',
      angle: '135deg'
    })}
`;

export const HeaderCloseButton = styled.button`
  ${buttonStyles};
`;

export const HeaderTitleWrapper = styled(FlexCenterAligned)`
  gap: 5px;
`;

export const HeaderTitle = styled(Typography)`
  margin: 0;
  text-align: center;
`;

export const HeaderRightAction = styled.div`
  top: 0;
  right: 15px;
  position: absolute;
  height: 100%;
`;

export const HeaderBottom = styled(FlexCenterAligned)`
  padding-bottom: 10px;
`;
