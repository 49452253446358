import { Dispatch } from 'react';

export type ChangeFuncType = (
  name: string,
  value?: undefined | string | string[]
) => void;
export type Value = undefined | string | boolean;
export type ValuesType = { [key: string]: Value };

interface SetCurrentStateAction {
  type: 'SET_CURRENT_STATE';
  payload: {
    city_id: string;
    leased: string;
    category_id?: string;
    paid_daily: string[];
  };
}
export type DispatchType = Dispatch<SetCurrentStateAction>;

export interface RentalTabsProps {
  values: ValuesType;
}

export interface onClickProps {
  change: ChangeFuncType;
  dispatch: DispatchType;
  values: ValuesType;
}

export enum RentalTabsIdEnum {
  buyTab = 'buy-link',
  rentTab = 'rent-link',
  rentDailyTab = 'paid_daily-link'
}
