import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Form, FormRenderProps, FormSpy } from 'react-final-form';
import { withApollo } from '@apollo/client/react/hoc';
import dynamic from 'next/dynamic';
import { NewSearchCategories } from './NewSearchCategories';
import {
  convertSearchValues,
  convertValues,
  fromObjToUrl
} from '../../utils/helpers';
import {
  setChosenLocationNames,
  setCurrentCity,
  setCurrentState,
  setLocationIds,
  setCategoryIds,
  setResetButtonIsDisabled,
  setAdriverSelectedCity
} from '../../actions';
import { getLocalDataQuery } from '../../queries';
import { SearchTypeHeader } from './SearchTypeHeader';
import {
  ANY_CITY_ID,
  BUILDING_TYPE_ID,
  Cities,
  SEARCH_RESULT_ROUTES
} from '../../utils/helpers/constants';
import { FormData, NewSearchProps } from './types';
import {
  selectSearchFormCurrentCity,
  selectSearchFormCurrentSortType,
  selectSearchFormCurrentState,
  selectSearchFormLocationIds
} from '../../store/selectors';
import {
  SearchBarForm,
  SearchContainer,
  TopSearchLineContainer
} from './styled';
import useScreenSize from '../../hooks/useScreenSize';
import { useClient } from '../../hooks/useClient';
import { RentalTabs } from './RentalTabs';
import { NewSearchBarPlaceholder } from '../Placeholders/NewSearchBarPlaceholder';
import { AllItemsLink } from './AllItemsLink';

const NewSearchBar = dynamic(
  () => import('./NewSearchBar').then(mod => mod.NewSearchBar),
  {
    loading: NewSearchBarPlaceholder,
    ssr: false
  }
);

const getInitialSearchType = (url: string, leased?: string) => {
  switch (true) {
    case /#more/.test(url) || /^\/$/.test(url):
      return undefined;
    case /\/alqi-satqi/.test(url):
      return 'buy';
    case /\?paid_daily=true/.test(url):
      return 'paid_daily';
    case /\/kiraye/.test(url):
      return 'rent';
    case leased === 'true':
      return undefined;
    case leased && leased !== 'true':
      return 'buy';
    default:
      return undefined;
  }
};

export const NewSearch = withApollo(
  ({
    client,
    formElToAssign,
    isHomePage = false,
    categories
  }: NewSearchProps) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const isClient = useClient();

    const formEl = useRef<{ reset: (values: FormData) => void } | null>(null);
    const { isSmall } = useScreenSize();
    const isSalePage = router.asPath === SEARCH_RESULT_ROUTES.SALE;
    const isRentPage =
      router.asPath === SEARCH_RESULT_ROUTES.RENT ||
      router.asPath === SEARCH_RESULT_ROUTES.RENT_DAILY;
    const isItemsAllPage = router.pathname === SEARCH_RESULT_ROUTES.ITEMS_ALL;

    const storedFormState = useSelector(selectSearchFormCurrentState);
    const sortingValue = useSelector(selectSearchFormCurrentSortType);
    const currentCity: string = useSelector(selectSearchFormCurrentCity);
    const storedLocationIds: string[] = useSelector(
      selectSearchFormLocationIds
    );
    const categoryTouched = storedFormState?.category_touched || false;
    const [searchType, setSearchType] = useState<string | undefined | null>(
      getInitialSearchType(router.asPath)
    );

    const resetCb = () => {
      const resetData = {
        city_id: Cities.Baku.id,
        leased: 'false',
        category_id: BUILDING_TYPE_ID.FLAT_NEW,
        locations_ids: undefined,
        category_touched: false
      };
      formEl.current?.reset(resetData);
      dispatch(setChosenLocationNames([]));
      dispatch(setLocationIds([]));
      dispatch(setCurrentCity(Cities.Baku.id));
      dispatch(setCurrentState(resetData));
      dispatch(setAdriverSelectedCity(Cities.Baku.az));
      setSearchType(null);
    };

    useEffect(() => {
      setSearchType(
        getInitialSearchType(router.asPath, storedFormState?.leased)
      );
      if (router.asPath === '/') {
        resetCb();
      }
    }, [router.pathname, router.asPath]);

    const handleReset = () => {
      router.back();
      router.beforePopState(({ url }) => {
        if (url === '/') {
          resetCb();
        }
        return true;
      });
    };

    const onSubmit = async (values: FormData) => {
      const { url, ...queryObj } = router.query;
      dispatch(setCurrentState(values));
      dispatch(setCategoryIds([values.category_id]));

      await client
        ?.query({
          query: getLocalDataQuery(values.city_id),
          variables: {
            id: values.city_id
          }
        })
        .then(localData => {
          const { href, as } = fromObjToUrl(
            values,
            localData.data,
            queryObj.sorting
          );

          router.push(href, as);
        });
    };

    const changeFormHandler = ({ values }: { values: FormData }) => {
      const isEqual =
        JSON.stringify(
          convertSearchValues({
            city_id: ANY_CITY_ID,
            leased: 'false',
            category_id: BUILDING_TYPE_ID.FLAT_NEW
          })
        ) === JSON.stringify(convertValues(values));

      setTimeout(() => dispatch(setResetButtonIsDisabled(isEqual)), 0);
    };

    const initialValues = {
      city_id: currentCity || storedFormState?.city_id || ANY_CITY_ID,
      leased: String(searchType !== 'buy'),
      category_id: storedFormState?.category_id,
      paid_daily: String(searchType !== 'paid_daily') ? undefined : true,
      location_ids: storedLocationIds,
      sorting: sortingValue,
      ...storedFormState
    };

    const isCategoriesShown =
      isHomePage || isRentPage || isSalePage || isItemsAllPage;
    const isRentalBlockShown =
      !searchType || isSalePage || isRentPage || isItemsAllPage || isHomePage;
    const isAllItemsLinkShown = isHomePage && !isItemsAllPage;
    const isSearchTypeHeaderShown =
      isClient && searchType && isSmall && !isHomePage && !isItemsAllPage;

    return (
      <SearchContainer>
        <Form
          onSubmit={onSubmit}
          subscription={{
            submitting: true,
            values: true
          }}
          initialValues={initialValues}
        >
          {(renderProps: FormRenderProps<typeof initialValues>) => {
            const { values } = renderProps;
            formEl.current = renderProps.form;
            const formElToAssignCurrent = formElToAssign?.current;
            if (formElToAssignCurrent) {
              formElToAssignCurrent.reset = renderProps.form.reset;
            }
            const searchVariables = {
              filter: convertSearchValues(values, false, sortingValue)
            };

            return (
              <SearchBarForm
                method="get"
                id="new_search"
                acceptCharset="UTF-8"
                noValidate={true}
                onSubmit={renderProps.handleSubmit}
                isHomePage={isHomePage}
              >
                <FormSpy
                  onChange={changeFormHandler}
                  subscription={{ values: true }}
                />
                {isSearchTypeHeaderShown && (
                  <SearchTypeHeader
                    values={values}
                    searchType={searchType}
                    handleBack={handleReset}
                    hasCategory={categoryTouched}
                  />
                )}
                {isRentalBlockShown && (
                  <TopSearchLineContainer>
                    <RentalTabs values={values} />
                    {isAllItemsLinkShown && <AllItemsLink />}
                  </TopSearchLineContainer>
                )}
                <NewSearchBar
                  formEl={formEl}
                  searchVariables={searchVariables}
                  extraPadding={Boolean(!categoryTouched && searchType)}
                />
                {(!values.category_id || isCategoriesShown) && (
                  <NewSearchCategories categories={categories} asLinks />
                )}
              </SearchBarForm>
            );
          }}
        </Form>
      </SearchContainer>
    );
  }
);
