import styled, { css } from 'styled-components';
import { ItemCardPlaceholderProps } from '.';
import { animation } from '../../../styled/placeholders.styled';

const placeholderRow = css`
  height: 15px;
  background-color: ${({ theme: { colors } }) => colors.background.secondary};
  border-radius: 4px;
  margin: 5px 0;
`;

export const PlaceholderCardImg = styled.div`
  height: 130px;
  background-color: ${({ theme: { colors } }) => colors.background.secondary};
  border-radius: 14px;
  ${animation};

  @media (min-width: ${({ theme }) =>
      theme.tabletBreakpoint}) and (max-width: ${({ theme }) =>
      theme.smallDesktopBreakpoint}) {
    height: 150px;
  }

  @media (min-width: ${({ theme }) =>
      theme.smallDesktopBreakpoint}) and (max-width: ${({ theme }) =>
      theme.desktopBreakpoint}) {
    height: 204px;
  }

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    height: 173px;
  }
`;

export const PlaceholderCardPrice = styled.div`
  ${placeholderRow};
  ${animation};
  width: 71%;
  margin-top: 7.5px;
  @media (min-width: ${({ theme }) =>
      theme.tabletBreakpoint}) and (max-width: ${({ theme }) =>
      theme.smallDesktopBreakpoint}) {
    margin-top: 10.5px;
  }

  @media (min-width: ${({ theme }) =>
      theme.smallDesktopBreakpoint}) and (max-width: ${({ theme }) =>
      theme.desktopBreakpoint}) {
    margin-top: 5px;
  }

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    margin-top: 10px;
  }
`;

export const PlaceholderCardLocation = styled.div`
  ${placeholderRow};
  ${animation};
  width: 71%;
`;

export const PlaceholderCardDescription = styled.div`
  ${placeholderRow};
  ${animation};
  width: 60%;
`;

export const PlaceholderCardDate = styled.div`
  ${placeholderRow};
  ${animation};
  width: 50%;
`;

export const PlaceholderCard = styled.div<ItemCardPlaceholderProps>`
  width: 48.5%;
  margin-right: 2.9%;
  margin-top: 10px;

  &:nth-of-type(2n + 2) {
    margin: 0;
  }

  &:nth-of-type(-n + 4) {
    margin-top: 10px;
  }

  ${({ isCardsLayout }) =>
    isCardsLayout &&
    css`
      &:nth-of-type(-n + 4) {
        margin-top: 0;
      }

      & ${PlaceholderCardPrice} {
        height: 20px;
      }

      & ${PlaceholderCardLocation}, & ${PlaceholderCardDescription} {
        height: 18px;
      }

      & ${PlaceholderCardImg} {
        height: calc((48.5vw - 20px) * 0.74);

        @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
          height: calc((33.3vw - 30px) * 0.74);
        }

        @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
          height: calc((25vw - 130px) * 0.74);
        }
      }
    `}
`;
