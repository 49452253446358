import styled from 'styled-components';
import { Typography } from '../ui/Typography';
import { FlexibleDiv } from '../../styled/common';
import { TopSearchLineContainer } from '../NewSearch/styled';

export const SectionHeaderContainer = styled(TopSearchLineContainer)`
  margin: 0 0 15px;
  box-sizing: border-box;
`;

export const StyledLink = styled(Typography)`
  line-height: 20px;
  color: ${({ theme: { colors } }) => colors.text.primary};
`;

export const Headline = styled(Typography)`
  margin: 0;
  line-height: 20px;
  font-size: 16px;
`;

export const PremiumTitle = styled.h1`
  font-size: 11px;
  font-weight: 400;
  color: #bcbcbc;
  margin: 0;
  text-align: right;
`;

export const HeaderRightBlock = styled(FlexibleDiv)`
  gap: 14px;
  align-items: center;
`;
