import { BUILDING_TYPE_ID } from './constants';

export const getInitialCategoriesLinks = (isRent: boolean) => {
  const path = isRent ? '/baki/kiraye' : '/baki/alqi-satqi';

  return {
    [BUILDING_TYPE_ID.FLAT_ALL]: `${path}/menziller`,
    [BUILDING_TYPE_ID.HOUSE]: `${path}/heyet-evleri`,
    [BUILDING_TYPE_ID.OFFICE]: `${path}/ofisler`,
    [BUILDING_TYPE_ID.GARAGE]: `${path}/qarajlar`,
    [BUILDING_TYPE_ID.AREA]: `${path}/torpaq`,
    [BUILDING_TYPE_ID.OBJECT]: `${path}i/obyektler`
  };
};
