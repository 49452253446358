import React, { useEffect, useCallback, forwardRef, RefObject } from 'react';
import Loader from '../Loader';

interface EndlessScrollProps {
  items: [];
  loading: boolean;
  onLoadMore: () => void;
  isStopLoad: boolean;
  forwardedRef: RefObject<HTMLDivElement>;
}

const EndlessScroll: React.FC<
  EndlessScrollProps & { Component: React.ComponentType }
> = ({
  onLoadMore,
  loading,
  isStopLoad,
  forwardedRef,
  items,
  Component,
  ...props
}) => {
  const handleScroll = useCallback(() => {
    if (!forwardedRef?.current || loading || isStopLoad) return;

    const { top, height } = forwardedRef.current.getBoundingClientRect();
    const offset = window.innerHeight * 2;

    if (
      top + height + offset >= 0 &&
      top + height - offset <= window.innerHeight
    ) {
      onLoadMore();
    }
  }, [onLoadMore, loading, isStopLoad, forwardedRef]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <>
      <Component {...props} forwardedRef={forwardedRef} />
      <Loader
        loading={loading && !!items.length && !isStopLoad}
        optionalClass="endless-loader"
      />
    </>
  );
};

export const withEndlessScroll = <P extends object>(
  Component: React.ComponentType<
    P & { forwardedRef: RefObject<HTMLDivElement> }
  >
) => {
  const ForwardedComponent = forwardRef<
    HTMLDivElement,
    P & Omit<EndlessScrollProps, 'forwardedRef'>
  >((props, ref) => (
    <EndlessScroll {...props} forwardedRef={ref} Component={Component} />
  ));

  const name = Component.displayName || Component.name;
  ForwardedComponent.displayName = `withEndlessScroll(${name})`;

  return ForwardedComponent;
};
