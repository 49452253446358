import styled from 'styled-components';
import { FlexibleDiv } from '../../styled/common';

const bannerWidth = '220px';

export const LayoutContainer = styled(FlexibleDiv)`
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    max-width: 1000px;
    margin: auto;
    justify-content: center;
  }
`;

export const MainContent = styled.main`
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    width: calc(100vw - (2 * ${bannerWidth}));
  }
`;
