import styled, { css } from 'styled-components';
import { SearchBarContainer } from '../../NewSearch/NewSearchBar/styled';
import { animation } from '../../../styled/placeholders.styled';

type FilterProps = { $isSearchPage: boolean };

export const FilterRow = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const placeholderStyles = (isSearchPage: boolean) => css`
  height: 54px;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 14px;
  ${animation};

  ${isSearchPage &&
  css`
    @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
      height: 44px;
    }
  `}
`;

export const Filter = styled.div<FilterProps>`
  flex: 1 1 calc(50% - 10px);
  width: calc(50% - 10px);
  ${({ $isSearchPage }) => placeholderStyles($isSearchPage)};
`;

export const Location = styled.div<FilterProps>`
  ${({ $isSearchPage }) => placeholderStyles($isSearchPage)};
  flex: 1;
`;

export const Button = styled.div<FilterProps>`
  width: 53px;
  ${({ $isSearchPage }) => placeholderStyles($isSearchPage)};
`;

export const NewSearchBarPlaceholderContainer = styled(
  SearchBarContainer
)<FilterProps>`
  gap: 10px;
  width: auto;
  display: flex;

  ${FilterRow}:nth-child(-n + 2) {
    display: none;
  }

  ${({ $isSearchPage }) =>
    $isSearchPage &&
    css`
      @media screen and (max-width: ${({ theme }) => theme.tabletBreakpoint}) {
        flex-direction: column;
        margin-bottom: 15px;
        padding: 25px 15px;
        gap: 15px;

        ${FilterRow}:nth-child(-n + 2) {
          display: flex;
        }
      }
    `}
`;
