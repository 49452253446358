import { useQuery } from '@apollo/client';
import { bookmarkIdsQuery } from '../queries';
import {
  BookmarkIdsQuery,
  BookmarkIdsQueryVariables
} from '../graphql/graphql';

export const useBookmarks = () => {
  const { data } = useQuery<BookmarkIdsQuery, BookmarkIdsQueryVariables>(
    bookmarkIdsQuery
  );

  const isBookmarked = (id: string): boolean =>
    data?.bookmarks?.some(bookmark => bookmark.item.id === id) ?? false;

  return { bookmarkIds: data?.bookmarks || [], isBookmarked };
};
