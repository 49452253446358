import { ApolloClient } from '@apollo/client';

export const addBookmarkToCache = (
  newBookmarkId: string,
  client: ApolloClient<object>
) => {
  const newBookmark = {
    __typename: 'Bookmark',
    item: { id: newBookmarkId, __typename: 'Item' }
  };
  client.cache.modify({
    fields: {
      bookmarks(existing) {
        return [...existing, newBookmark];
      }
    },
    broadcast: true
  });
};

export const removeBookmarkFromCache = (
  removedBookmarkId: string,
  client: ApolloClient<object>
) => {
  client.cache.modify({
    fields: {
      bookmarks(existing, { readField }) {
        return existing.filter(
          item => readField('id', item.item) !== removedBookmarkId
        );
      }
    },
    broadcast: true
  });
};
