import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import { useRouter } from 'next/router';
import NewChips from '../common/NewChips';
import {
  InitialRentalTabsContainer,
  RentalTabsContainer,
  TabLink
} from './styled';
import { RentalTabsIdEnum, RentalTabsProps } from './types';
import { mobileTabsConfig, tabsConfig } from './tabsConfig';
import useScreenSize from '../../../hooks/useScreenSize';
import { Tab } from '../../ui/Tab';
import { SEARCH_RESULT_ROUTES } from '../../../utils/helpers/constants';
import { useClient } from '../../../hooks/useClient';

export const RentalTabs = ({ values }: RentalTabsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { change } = useForm();
  const { isSmall } = useScreenSize();
  const isClient = useClient();

  const router = useRouter();
  const isSalePage = router.asPath === SEARCH_RESULT_ROUTES.SALE;
  const isRentPage =
    router.asPath === SEARCH_RESULT_ROUTES.RENT ||
    router.asPath === SEARCH_RESULT_ROUTES.RENT_DAILY;

  if (isClient && (isSalePage || isRentPage) && isSmall) return null;

  const withRedirect = isSalePage || isRentPage;

  const handleRedirect = (tabId: RentalTabsIdEnum) => {
    if (tabId === RentalTabsIdEnum.buyTab) {
      router.push(SEARCH_RESULT_ROUTES.SALE);
    } else if (tabId === RentalTabsIdEnum.rentTab) {
      router.push(SEARCH_RESULT_ROUTES.RENT);
    }
  };

  if (isClient && !isSmall)
    return (
      <RentalTabsContainer data-cy="new-search__chips-wrapper">
        {tabsConfig.map(tab => (
          <Tab
            key={tab.id}
            id={tab.id}
            cypress={tab.cypress}
            text={t(tab.text)}
            onClick={() => {
              tab.onClick({ change, values, dispatch });
              if (withRedirect) handleRedirect(tab.id);
            }}
            isSelected={tab.getIsSelected(values.leased)}
            stat={tab.stat}
          />
        ))}
      </RentalTabsContainer>
    );

  return (
    <InitialRentalTabsContainer data-cy="new-search__chips-wrapper">
      {mobileTabsConfig.map(tab => (
        <TabLink key={tab.id} id={tab.id} href={tab.href} locale={false}>
          <NewChips
            type="button"
            icon={tab.icon}
            cypress={tab.cypress}
            borderRadius="14px"
            style={{ marginButton: 0 }}
            text={t(tab.text)}
            stat={tab.stat}
            onClick={() => tab.onClick({ change, values, dispatch })}
            isTextPadding={false}
          />
        </TabLink>
      ))}
    </InitialRentalTabsContainer>
  );
};
