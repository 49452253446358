import { ReactNode } from 'react';

export interface NewSectionProps {
  id: string;
  title: string | ReactNode;
  url?: string;
  asLink?: string;
  noHeader?: boolean;
  rightBlock?: ReactNode;
  urlTitle?: string;
  children: ReactNode;
  headlineType?: string;
  cypress?: string;
  hasHorizontalScroll?: boolean;
}

export enum DirectionEnum {
  left = 'left',
  right = 'right'
}

export interface SliderButtonProps {
  onClick: () => void;
  disabled: boolean;
  direction: DirectionEnum;
}
