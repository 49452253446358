import styled, { css } from 'styled-components';
import { FlexibleDiv } from '../../../../styled/common';

const filtersSmallSize = ' 44px';
const filtersBigSize = '54px';

export const SearchPageFiltersContainer = styled(FlexibleDiv)<{
  canGoBack?: boolean;
}>`
  width: 100%;
  flex-wrap: ${({ canGoBack }) => (canGoBack ? 'nowrap' : 'wrap')};
  gap: 15px 10px;

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    flex-wrap: nowrap;
  }
`;

export const FilterWrapper = styled.div`
  flex: 1 1 calc(50% - 10px);
  width: calc(50% - 10px);

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    flex: 0 0 auto;

    &:not(:last-of-type) {
      min-width: 80px;
      max-width: 13.5%;
    }

    &:first-child {
      max-width: 100px;
    }

    &:nth-child(2) {
      max-width: 135px;
    }
  }

  > button {
    border: 1px solid ${({ theme }) => theme.colors.border.primary};
    border-radius: 14px;
    height: ${filtersSmallSize};
    width: 100%;

    @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
      height: ${filtersBigSize};
    }
  }
`;

export const SearchBarWrapper = styled.div<{ canGoBack?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  width: ${({ canGoBack }) =>
    canGoBack
      ? `calc(92% - ${filtersSmallSize} - 10px)`
      : `calc(100% - ${filtersSmallSize} - 10px)`};
  height: ${filtersSmallSize};

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    width: 100%;
    height: ${filtersBigSize};
  }

  > div {
    height: inherit;
  }
`;

export const GoBackButton = styled.div`
  width: 14px;
  cursor: pointer;
  display: inline-block;
  background: url('/static/images/item/arrow-right.svg') no-repeat center;
  background-size: 16px 16px;
  transform: rotate(180deg);
  -webkit-tap-highlight-color: transparent;
`;

export const filterButtonStyles = css`
  width: ${filtersSmallSize};
  height: ${filtersSmallSize};

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    width: ${filtersBigSize};
    height: ${filtersBigSize};
    padding: 17px 17px;
  }

  @media (min-width: ${({ theme }) =>
      `calc(${theme.desktopBreakpoint} + 1px)`}) {
    width: 128px;
    > span {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  path {
    ${({ theme: { colors } }) => {
      const color = colors.background.primary;
      return { fill: color, stroke: color };
    }}
  }
`;
