import styled, { css } from 'styled-components';
import { CardsLayoutProps } from './types';

const bannerSize = '220px';
const tabletPadding = '40px';
const desktopPadding = '20px';

export const CardsContainer = styled.div<CardsLayoutProps>`
  position: relative;
  font-size: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px 0 5px;

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding: 0 40px 0 30px;
  }
  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding: 0 20px 0 10px;
  }

  ${({ hasHorizontalScroll }) =>
    hasHorizontalScroll &&
    css`
      display: flex;
      flex-wrap: nowrap;
      padding: 0 15px 15px 15px;
      overflow-x: scroll;
      position: relative;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      scroll-behavior: smooth;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
        padding-left: ${tabletPadding};
        padding-right: ${tabletPadding};
      }

      @media (min-width: ${({ theme }) =>
          `calc(${theme.smallDesktopBreakpoint} + 1px)`}) {
        width: calc(100vw - (2 * ${tabletPadding}));
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
      }

      @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
        width: calc(100vw - (2 * ${bannerSize}) - (2 * ${desktopPadding}));
      }

      @media (min-width: 1460px) {
        width: 960px;
      }
    `}
`;
