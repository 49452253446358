import styled, { css } from 'styled-components';
import { NewTabProps } from './types';
import { Typography } from '../Typography';

export const TabContainer = styled.button<NewTabProps>`
  display: block;
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  padding: 10px 15px;
  box-sizing: border-box;
  color: ${({ theme: { colors } }) => colors.text.primary};
  cursor: pointer;

  &:hover {
    ${({ $isSelected }) =>
      !$isSelected &&
      css`
        span {
          color: ${({ theme }) => theme.colors.systemColors.blue};
        }
      `};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.background.primary};
      border-bottom: 1px solid ${({ theme }) => theme.colors.systemColors.brand};
    `};

  &:first-of-type {
    border-top-left-radius: 7px;
  }

  &:last-of-type {
    border-top-right-radius: 7px;
  }
`;

export const TabText = styled(Typography)`
  transition: color 0.3s ease;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${({ theme }) => theme.colors.systemColors.brand};
    `};
`;
