import { useRouter } from 'next/router';
import {
  Button,
  Filter,
  FilterRow,
  Location,
  NewSearchBarPlaceholderContainer
} from './styled';

export const NewSearchBarPlaceholder = () => {
  const router = useRouter();

  const isSearchPage = router.pathname === '/[...search]';

  return (
    <NewSearchBarPlaceholderContainer $isSearchPage={isSearchPage}>
      <FilterRow>
        <Filter $isSearchPage={isSearchPage} />
        <Filter $isSearchPage={isSearchPage} />
      </FilterRow>
      <FilterRow>
        <Filter $isSearchPage={isSearchPage} />
        <Filter $isSearchPage={isSearchPage} />
      </FilterRow>

      <FilterRow>
        <Location $isSearchPage={isSearchPage} />
        <Button $isSearchPage={isSearchPage} />
      </FilterRow>
    </NewSearchBarPlaceholderContainer>
  );
};
