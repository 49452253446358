import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useForm, useFormState } from 'react-final-form';
import { useRouter } from 'next/router';
import Icon from '../../Icon';
import {
  fillCategoriesWithData,
  fromObjToUrl,
  getCategoriesData
} from '../../../utils/helpers/search';
import {
  CategoriesContainer,
  CategoryContainer,
  CategoryIcon,
  CategoryLinkContainer,
  CategoryText
} from './styled';
import { NewSearchCategoriesProps, NewSearchCategoryProps } from './types';
import { categoriesQuery, getLocalDataQuery } from '../../../queries';
import { useStaticData } from '../../../hooks/useStaticData';
import { getInitialCategoriesLinks } from '../../../utils/helpers/getInitialCategoriesLinks';

const NewSearchCategory = ({
  icon,
  text,
  onClick,
  stat,
  isSelected,
  link
}: NewSearchCategoryProps) => {
  const Component =
    typeof link === 'string' ? CategoryLinkContainer : CategoryContainer;

  return (
    <Component
      data-cy={stat}
      data-stat={stat}
      onClick={onClick}
      onKeyPress={onClick}
      $isSelected={isSelected}
      href={link?.toString()}
      locale={false}
    >
      <CategoryIcon>
        <Icon
          width={null}
          height={null}
          color="#fff"
          indent={false}
          IconComponent={icon}
        />
      </CategoryIcon>
      <CategoryText tag="body_1">{text}</CategoryText>
    </Component>
  );
};

export const NewSearchCategories = ({
  onClick,
  selectedValue,
  categories,
  asLinks = false
}: NewSearchCategoriesProps) => {
  const router = useRouter();
  const { change } = useForm();
  const { values } = useFormState();

  const { data } = useStaticData(categoriesQuery, !!categories?.length);
  const {
    i18n: { language }
  } = useTranslation();
  const categoriesData = categories || getCategoriesData({ data, language });
  const filledCategories = fillCategoriesWithData(categoriesData);

  const { data: localData } = useQuery(getLocalDataQuery(values.city_id), {
    variables: {
      id: values.city_id
    },
    skip: !asLinks
  });

  const handleClick = (value: string) => {
    if (onClick) {
      onClick(value);
    } else {
      change?.('category_id', value);
      change?.('category_touched', true);
    }
  };

  const initialCategoriesLinks = getInitialCategoriesLinks(
    router.asPath === '/kiraye'
  );

  const generateCategoryUrl = (categoryId: string) => {
    if (!localData) return initialCategoriesLinks[categoryId];

    const { url, ...queryObj } = router.query;
    const { as } = fromObjToUrl(
      { ...values, category_id: categoryId },
      localData,
      queryObj.sorting
    );

    return as;
  };

  return (
    <CategoriesContainer data-cy="categories-container">
      {filledCategories.map(category => (
        <NewSearchCategory
          key={category.id}
          stat={category.stat}
          icon={category.icon}
          text={category.name}
          onClick={() => handleClick(category.id)}
          isSelected={category.id === selectedValue}
          link={asLinks && generateCategoryUrl(category.id)}
        />
      ))}
    </CategoriesContainer>
  );
};
