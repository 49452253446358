import PasswordIcon from '../../../public/static/images/new_search/icons/icon-password.svg';
import CalendarIcon from '../../../public/static/images/new_search/icons/icon-calendar.svg';
import MoonIcon from '../../../public/static/images/new_search/icons/icon-moon.svg';
import { setAdriverSelectedCity, setCurrentState } from '../../../actions';
import { onClickProps, RentalTabsIdEnum, Value } from './types';
import {
  Cities,
  BUILDING_TYPE_ID,
  SEARCH_RESULT_ROUTES
} from '../../../utils/helpers/constants';

export const mobileTabsConfig = [
  {
    id: RentalTabsIdEnum.buyTab,
    href: SEARCH_RESULT_ROUTES.SALE,
    cypress: 'tab-link',
    icon: PasswordIcon,
    text: 'new_search.sell',
    stat: 'new-search-buy-button',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('category_id', BUILDING_TYPE_ID.FLAT_NEW);
      change('city_id', Cities.Baku.id);
      change('paid_daily', undefined);
      dispatch(
        setCurrentState({
          ...values,
          city_id: Cities.Baku.id,
          leased: 'false',
          category_id: BUILDING_TYPE_ID.FLAT_NEW
        })
      );
      dispatch(setAdriverSelectedCity(Cities.Baku.az));
    }
  },
  {
    id: RentalTabsIdEnum.rentTab,
    href: SEARCH_RESULT_ROUTES.RENT,
    cypress: 'tab-link',
    icon: CalendarIcon,
    text: 'new_search.rent',
    stat: 'new-search-rent-button',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('category_id', BUILDING_TYPE_ID.FLAT_NEW);
      change('leased', 'true');
      dispatch(
        setCurrentState({
          ...values,
          city_id: Cities.Baku.id,
          leased: 'true',
          category_id: BUILDING_TYPE_ID.FLAT_NEW
        })
      );
      dispatch(setAdriverSelectedCity(Cities.Baku.az));
    }
  },
  {
    id: RentalTabsIdEnum.rentDailyTab,
    href: {
      pathname: SEARCH_RESULT_ROUTES.RENT,
      query: { paid_daily: true }
    },
    icon: MoonIcon,
    cypress: 'tab-link',
    text: 'search.form.paid_daily',
    stat: 'new-search-paid-daily-button',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('category_id', BUILDING_TYPE_ID.FLAT_NEW);
      change('leased', 'true');
      change('paid_daily', ['true']);
      dispatch(
        setCurrentState({
          ...values,
          city_id: Cities.Baku.id,
          leased: 'true',
          category_id: BUILDING_TYPE_ID.FLAT_NEW,
          paid_daily: ['true']
        })
      );
      dispatch(setAdriverSelectedCity(Cities.Baku.az));
    }
  }
];

export const tabsConfig = [
  {
    id: RentalTabsIdEnum.buyTab,
    cypress: 'tab-link',
    text: 'new_search.purchase',
    stat: 'new-search-buy-tab',
    getIsSelected: (value: Value) => value === 'false',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('leased', 'false');
      change('category_id', BUILDING_TYPE_ID.FLAT_NEW);
      change('paid_daily', undefined);
      dispatch(setCurrentState({ ...values, leased: 'false' }));
    }
  },
  {
    id: RentalTabsIdEnum.rentTab,
    cypress: 'tab-link',
    text: 'new_search.lease',
    stat: 'new-search-rent-tab',
    getIsSelected: (value: Value) => value === 'true',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('category_id', BUILDING_TYPE_ID.FLAT_NEW);
      change('leased', 'true');
      dispatch(setCurrentState({ ...values, leased: 'true' }));
    }
  }
];
