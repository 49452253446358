/* eslint-disable react/prop-types */
import { CloseIcon } from '../../../icons';
import { palette } from '../../../styles/_palette';
import {
  Header,
  HeaderLeftAction,
  HeaderBackButton,
  HeaderCloseButton,
  HeaderTitle,
  HeaderTitleWrapper,
  HeaderRightAction,
  HeaderBottom
} from './styled';
import { IconEnum, PageHeaderProps } from './types';

export const PageHeader: React.FC<PageHeaderProps> = ({
  id,
  titleCypress,
  title,
  titleIcon,
  height = 60,
  icon = IconEnum.backButton,
  backButtonCypress,
  rightBlock,
  bottomBlock,
  onClick
}) => {
  return (
    <>
      <Header id={id} height={height}>
        <HeaderLeftAction>
          {icon === IconEnum.backButton ? (
            <HeaderBackButton
              data-cy={backButtonCypress}
              onClick={onClick}
              type="button"
            />
          ) : (
            <HeaderCloseButton type="button" onClick={onClick}>
              <CloseIcon fill={palette.heatheredGrey} width={15} height={15} />
            </HeaderCloseButton>
          )}
        </HeaderLeftAction>

        {title && (
          <HeaderTitleWrapper>
            {titleIcon}
            <HeaderTitle data-cy={titleCypress} tag="h3">
              {title}
            </HeaderTitle>
          </HeaderTitleWrapper>
        )}

        {rightBlock && <HeaderRightAction>{rightBlock}</HeaderRightAction>}
      </Header>
      {bottomBlock && <HeaderBottom>{bottomBlock}</HeaderBottom>}
    </>
  );
};
