import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import Link from 'next/link';
import styled from 'styled-components';
import Bugsnag from '../../../utils/bugsnag';
import { Typography } from '../../ui/Typography';
import { allItemsCount } from '../../../queries/allItemsCount';
import { AllItemsCountQuery } from '../../../graphql/graphql';
import useScreenSize from '../../../hooks/useScreenSize';

const StyledLink = styled(Link)`
  text-decoration: none;

  @media (max-width: ${({ theme }) => theme.smallDesktopBreakpoint}) {
    display: none;
  }
`;

const Text = styled(Typography)`
  padding-bottom: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.primary};
`;

export const AllItemsLink: React.FC = () => {
  const { t } = useTranslation();
  const { isSmallDesktop, isLarge } = useScreenSize();

  const { data, error } = useQuery<AllItemsCountQuery>(allItemsCount, {
    skip: !(isSmallDesktop || isLarge)
  });

  const count = data?.itemsPublishedTodayCount || 0;

  if (error) Bugsnag.notify(error);
  const dataId = 'all-items-link';

  return (
    <StyledLink
      href="/items/all"
      data-cy={dataId}
      data-stat={dataId}
      locale={false}
    >
      <Text>{t('search.all_items_link', { count })}</Text>
    </StyledLink>
  );
};
