import { gql } from '@apollo/client';

export const allItemsCount = gql`
  query AllItemsCount {
    itemsPublishedTodayCount
  }
`;

const temp = 'just for eslint';

export default temp;
