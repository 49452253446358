import styled, { css } from 'styled-components';
import { FlexibleDiv } from '../../../styled/common';
import { Typography } from '../../ui/Typography';
import { Separator } from '../../ui/RangeFilter/styled';
import { StyledDeleteIcon } from '../../ui/Input/styled';
import { SearchBarWrapper } from './SearchPageFilters/styled';

const searchBarSize = '54px';

const buttonBorder = css`
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
`;
export const filterButtonStyles = (isSmall: boolean) => css`
  width: ${searchBarSize};
  height: ${searchBarSize};
  padding: 17px 16px;
  ${!isSmall && buttonBorder}

  path {
    ${({ theme: { colors } }) => {
      const color = isSmall ? colors.background.primary : colors.text.primary;
      return { fill: color, stroke: color };
    }}
  }
`;

export const buttonStyles = css`
  width: 77px;
  height: ${searchBarSize};
  padding: 18px 15px;
`;

const searchFiltersContainerStyles = (isFixed: boolean) => css`
  padding: 25px 15px;
  margin-bottom: 15px;
  background-color: ${({ theme }) =>
    isFixed
      ? theme.colors.background.primary
      : theme.colors.background.tertiary};
  border-radius: ${isFixed ? '0' : '21px 21px 0 0'};

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding-top: 10px;
    padding-bottom: 35px;
    margin-bottom: 0;
    background-color: ${({ theme }) => theme.colors.background.primary};
    border-radius: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding-bottom: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

export const SearchBarContainer = styled(FlexibleDiv)<{
  extraPadding: boolean;
  isSearchPage: boolean;
  $isHomePage?: boolean;
  isFixed?: boolean;
  canGoBack?: boolean;
}>`
  padding: 0 15px 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  z-index: ${({ isFixed }) =>
    isFixed ? '70000' : 'auto'}; // 7000 - to hide bg-banner
  background-color: ${({ theme }) => theme.colors.background.primary};

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding-right: 40px;
    padding-left: 40px;
    gap: 5px;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding-right: 20px;
    padding-left: 20px;
  }

  ${({ isSearchPage, isFixed }) =>
    isSearchPage && searchFiltersContainerStyles(isFixed ?? false)}

  ${({ isFixed, theme, canGoBack }) =>
    isFixed &&
    css`
      ${canGoBack &&
      css`
        ${SearchBarWrapper} {
          width: 100%;
        }
      `}
      @media (max-width: ${theme.tabletBreakpoint}) {
        padding: 10px 15px;
      }
      @media (min-width: ${theme.tabletBreakpoint}) {
        padding: 15px 40px;
      }
      @media (min-width: ${theme.desktopBreakpoint}) {
        padding: 15px 240px;
      }
    `}
`;

export const FiltersFloatButton = styled(FlexibleDiv)`
  position: fixed;
  right: 5px;
  bottom: 95px;
  z-index: 2;
  color: ${({ theme }) => theme.colors.background.primary};
  font-size: 15px;
  height: 38px;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.systemColors.blue};
  opacity: 0;
  transition: opacity 0.4s ease;
`;

export const MapLink = styled.a`
  ${buttonStyles}
  ${buttonBorder}
  display: flex;
  justify-items: center;
  align-items: center;
  border-radius: 14px;
  text-decoration: none;
`;

export const MapLinkText = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Divider = styled.div`
  width: 1px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.border.secondary};
  margin: 9px 0;
`;

export const FiltersLayout = styled(FlexibleDiv)`
  width: 100%;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  flex: 1;
  min-width: 0;

  > :first-child {
    border-radius: 14px;
  }
`;

export const actionButtonStyles = css`
  position: static;
  height: 48px;
  border: none;
  padding: 0;
`;

export const RangeFilterWrapper = styled.div`
  padding-bottom: 20px;

  div:not([role='button']):not(${Separator}),
  input {
    width: 100%;
    box-sizing: border-box;

    > ${StyledDeleteIcon} {
      top: 12px;
    }
  }
`;
