import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { SearchTypeHeaderProps } from './types';
import { PageHeader } from '../../ui/PageHeader';

const categoryMap = {
  1: {
    buy: 'new_search.flat0',
    rent: 'new_search.flat0',
    paid_daily: 'new_search.flat'
  },
  2: {
    buy: 'new_search.flat0',
    rent: 'new_search.flat0',
    paid_daily: 'new_search.flat'
  },
  3: {
    buy: 'new_search.flat0',
    rent: 'new_search.flat0',
    paid_daily: 'new_search.flat'
  },
  5: {
    buy: 'new_search.houseVilla0',
    rent: 'new_search.houseVilla0',
    paid_daily: 'new_search.houseVilla'
  },
  7: {
    buy: 'new_search.office',
    rent: 'new_search.office',
    paid_daily: 'new_search.office'
  },
  8: {
    buy: 'new_search.garage',
    rent: 'new_search.garage',
    paid_daily: 'new_search.garage'
  },
  9: {
    buy: 'new_search.land',
    rent: 'new_search.land',
    paid_daily: 'new_search.land'
  },
  10: {
    buy: 'new_search.object',
    rent: 'new_search.object',
    paid_daily: 'new_search.object'
  }
};

export const SearchTypeHeader = ({
  values,
  handleBack,
  searchType,
  hasCategory
}: SearchTypeHeaderProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const categoryId = values.category_id;
  const category = categoryMap[categoryId]?.[searchType] || '';

  const isSalePage = router.asPath === '/alqi-satqi';
  const isRentPage = router.asPath === '/kiraye';

  const titleMap = {
    buy: isSalePage ? 'main_menu.sale' : 'new_search.purchase',
    rent: isRentPage ? 'main_menu.lease' : 'new_search.lease',
    paid_daily: 'search.form.paid_daily'
  };

  const title = titleMap[searchType];

  return (
    <PageHeader
      id="popup_header"
      title={`${t(title)}${hasCategory ? t(category) : ''}`}
      backButtonCypress="new-search-popup-header-btn-close"
      titleCypress="new-search-title"
      onClick={handleBack}
    />
  );
};
