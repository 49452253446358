import styled, { keyframes, css } from 'styled-components';
import { Theme } from './theme/types';

export const placeload = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

export const animation = css<{ theme: Theme }>`
  animation: ${placeload} 1s forwards infinite linear;
  background: ${({ theme: { colors } }) =>
    `linear-gradient(to right, ${colors.background.secondary} 8%, ${colors.border.primary} 18%, ${colors.background.secondary} 33%)`};
  background-size: 1200px 104px;
`;

export const PlaceholderBlock = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '18px'};
  margin: ${props => props.margin || '10px 0'};
  border-radius: ${props => props.borderRadius || '7px'};
  ${animation}
`;
