import React, { ReactNode } from 'react';
import { LayoutContainer, MainContent } from './styled';
import { useTargetedBanner } from '../../hooks/useTargetedBanner';
import { TargetedBannerIds } from '../../hooks/useTargetedBanner/types';

export const HomeLayoutWithBanner = ({ children }: { children: ReactNode }) => {
  useTargetedBanner(TargetedBannerIds.bg);

  return (
    <LayoutContainer>
      <MainContent>{children}</MainContent>
    </LayoutContainer>
  );
};
