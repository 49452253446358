import React from 'react';
import {
  PlaceholderCard,
  PlaceholderCardDate,
  PlaceholderCardDescription,
  PlaceholderCardImg,
  PlaceholderCardLocation,
  PlaceholderCardPrice
} from './styled';

export interface ItemCardPlaceholderProps {
  isCardsLayout?: boolean;
}

export const ItemCardPlaceholder: React.FC<ItemCardPlaceholderProps> = ({
  isCardsLayout = false
}) => {
  return (
    <PlaceholderCard isCardsLayout={isCardsLayout}>
      <PlaceholderCardImg />
      <PlaceholderCardPrice />
      <PlaceholderCardLocation />
      <PlaceholderCardDescription />
      <PlaceholderCardDate />
    </PlaceholderCard>
  );
};
