import styled from 'styled-components';
import { FlexBetween, FlexColumn } from '../../styled/common';

export const SearchContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.background.primary};
  align-items: flex-start;
  width: 100%;
`;

export const SearchBarForm = styled.form<{ isHomePage: boolean }>`
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.smallDesktopBreakpoint}) {
    padding-top: 15px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TopSearchLineContainer = styled(FlexBetween)`
  padding: 0 15px 15px;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding-right: 40px;
    padding-left: 40px;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding-right: 20px;
    padding-left: 20px;
  }

  .chips-container {
    margin-bottom: 0;
  }
`;
