import { ReactNode } from 'react';

export enum IconEnum {
  backButton = 'backButton',
  closeButton = 'closeButton'
}

export interface PageHeaderProps {
  id?: string;
  title?: string;
  titleCypress?: string;
  backButtonCypress?: string;
  titleIcon?: ReactNode;
  rightBlock?: ReactNode;
  icon?: IconEnum;
  height?: number;
  bottomBlock?: ReactNode;
  onClick: () => void;
}
